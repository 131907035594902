<template>
  <section id="section-what">
    <div class="section-what__wrap">
      <div class="section__head section-what__head">
        <h3>WHAT IS<span></span>GOLD EXPERIENCE</h3>
      </div>
      <div class="section-what__subhead">
        <p class="section-what__subhead-text">
          それは本格的かつ革新的なパワーみなぎる体験
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
/* section-what */
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */

#section-what {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-size: cover;
  position: relative;
  padding: 70px 0 0;
  background-image: url("../../assets/lp_new/section_what_back_item.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #151515;
  height: 450px;
}

.section-what__wrap {
  width: 100%;
  max-width: 1020px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section__head {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  margin: 0 auto 30px;
}

.section__head::after,
.section__head::before {
  content: "";
  background-image: url("../../assets/lp_new/section_head_item.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 30px;
  display: block;
}

.section__head h3 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 60px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  color: #a69c7d;
  letter-spacing: 2px;
  padding: 0 30px;
}

.section__head h3 span {
  display: block;
  width: 30px;
}

.section-what__subhead {
  margin-top: 15px;
}

.section-what__subhead p {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 70px;
}

@media screen and (max-width: 1020px) {
  .section__head {
    margin: 0 auto 15px;
  }

  .section__head h3 {
    font-size: 26px;
  }

  #section-what {
    height: 300px;
    background-size: cover;
  }

  .section-what__subhead p {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  #section-what {
    height: 210px;
  }
  .section__head h3 {
    font-size: 16px;
    line-height: 16px;
  }

  .section__head::after,
  .section__head::before {
    width: 12px;
    height: 18px;
    display: block;
  }

  .section-what__subhead p {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
}
</style>
